import React from "react"
import { graphql } from "gatsby"
import Layout from "../site/Layout"
import * as Sections from "../components/SectionTypes"
import { ContentfulTemplateBodyText } from "../components/AllComponents"
// import CookieConsent, { Cookies } from "react-cookie-consent";
export const query = graphql`
  query ($contentful_id: String!) {
    contentfulPage(contentful_id: {eq: $contentful_id}) {
      entryTitle
      title
      description
      categories
      tags
      publishedDate
      showSectionsTabs
      urlSlug
      sections {
        __typename
        ... on ContentfulTemplateHeader {
          heading
          subHeading
          heroImage {
            file {
              url
            }
            title
          }
          buttons {
            __typename
            ...form
          }
        }
        ... on ContentfulTemplateSection {
          heading
          cssClass
          background {file{url}}
          columns {
            entryTitle
            desktopGridType
            tabletGridType
            mobileGridType
            textPosition
            cssClasses
            components {
              ...bodyText
              ...button
              ...form
              ...tile
              ...video
              ...on ContentfulPage {
                title
                description
                pageImage {
                  file {
                    url
                  }
                  title
                }
                urlSlug
                pageType
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata{
        email phone_number phone {
          en_GB {
              phone_number
              title
          }
          en_US {
              phone_number
              title
          }
      } 
      }
    }
  }
  fragment button on ContentfulTemplateButton {
    title
    targetContent
    targetType
  }
  
  fragment bodyText on ContentfulTemplateBodyText {
    heading
    subHeading
    bodyText {json}
  }
  
  fragment form on ContentfulTemplateForm {
    entryTitle
    title
    formType
    url
  }
  
  fragment tile on ContentfulTemplateTile {
    entryTitle
    heading
    headingSize
    text
    image {file{url} title}
    link
    imageClasses
  }
  
  fragment video on ContentfulTemplateVideo {
    entryTitle
    placeholderImage {file { url }}
    videoUrl
  }
`
const Page = ({ data: { contentfulPage: { title, description, sections }, site: { siteMetadata } } }) => {
  return (
    <>
      <Layout {...{ title, description, siteMetadata }}>
        {
          sections.map((section, i) => {
            let Section = Sections[section.__typename];
            return (
              <Section key={i} {...section} />
            )
          })
        }
      </Layout>
    </>
  )
}

export default Page